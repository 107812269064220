import React, { useState } from 'react'
import { connect } from 'react-redux'

import { ApiCall_GET } from '../../../Redux/Generic/action'

import { useAppContext } from '../../../AppContextProvider ';
import { useLocation, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import { replaceEmptyObjectsWithNull, calculateBMIpatient, getPatientInformation, getColorAndCategory, genderIcon } from '../../../common';


const CoupleInfo = ({ ApiCall_GET, coupleid, PSselectedPage }) => {

  const location = useLocation();
  let navigate = useNavigate();
  const theme = useAppContext().themeDetails;
  const [activeStep, setActiveStep] = useState(1);
  const [isSideBarShown, setisSideBarShown] = useState(false);
  const [coupleData, setcoupleData] = useState(1);
  // var patientInfo = getPatientInformation()


  useEffect(() => {
    ApiCall_GET('patient/LoadCoupleDetailsByCoupleId/' + coupleid, true).then(function (payload) {
      payload = replaceEmptyObjectsWithNull(payload)

      setcoupleData(payload)
    })

  }, [])

  const patientData = getColorAndCategory(calculateBMIpatient(Number(coupleData.PatientHeight), Number(coupleData.PatientWeight)));
  const partnerData = getColorAndCategory(calculateBMIpatient(Number(coupleData.PartnerHeight), Number(coupleData.PartnerWeight)));

  // useEffect(() => {
  //   const patientInfo = getUserInformation('patient')

  //   ApiCall_GET('patient/GetPatientDetailsByCoupleId/' + coupleId, true).then(function (payload) {
  //   },[])

  const toggleSideBar = () => {
    setisSideBarShown(!isSideBarShown)
  }
  // const handelActiveStep = (step) => {
  //   setActiveStep(step);
  // };

  const handleTabClick = (step) => {
    setActiveStep(step);
  };
  return (
    <div className='w-full  h-full pb-2'>
      {/* <div className={isSideBarShown ? 'w-5/6   h-full ' : 'w-full h-full bg-white rounded '}> */}
      {/* <div className={theme.bg_Light + ' flex items-between p-0.5  pl-2 rounded shadow '}>
          <div className='w-2/5 text-left'>
            <label className={theme.txt_color + ' pl-2text-base font-medium '}>Couple Info</label>
            <label className={theme.txt_color + ' ml-2 text-base font-medium '}> </label>
          </div>
          <div className='w-3/5 text-right flex justify-end'>
            <button
              onClick={() => navigate('/coupledetails', { state: { id: patientInfo.coupleId } })}
              className="flex items-center cursor-pointer justify-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:text-black hover:bg-yellow-400 focus:ring-4 focus:ring-gray-200 font-medium rounded text-sm px-3 py-1 mr-2"
            >
              <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.15716 0.607836C5.26072 0.711393 5.3125 0.834005 5.3125 0.975672C5.3125 1.11734 5.26072 1.23981 5.15716 1.34309L2.12293 4.37732L5.16752 7.42191C5.26417 7.51856 5.3125 7.63938 5.3125 7.78436C5.3125 7.92934 5.26072 8.05361 5.15716 8.15716C5.05361 8.26072 4.93099 8.3125 4.78933 8.3125C4.64766 8.3125 4.52519 8.26072 4.42191 8.15716L0.942381 4.66728C0.900958 4.62586 0.871548 4.58098 0.85415 4.53266C0.836753 4.48433 0.828192 4.43255 0.828468 4.37732C0.828468 4.32209 0.837167 4.27031 0.854564 4.22199C0.871962 4.17366 0.901234 4.12878 0.942381 4.08736L4.43226 0.59748C4.52892 0.500827 4.64808 0.4525 4.78974 0.4525C4.93141 0.4525 5.05388 0.504279 5.15716 0.607836Z" fill="#52575C" />
              </svg>
              <label className='pl-2 cursor-pointer'> Back to ART</label>
            </button>
          </div>
        </div> */}

      {/* Tab Body  */}
      {/* <div className={theme.thumb + ' ' + theme.track + ` bg-white relative  rounded-b--md shadow  overflow-y-auto scrollbar overflow-x-auto  scrollbar-thin h-[96%]`} > */}

      <div className={`${theme.thumb} ${theme.track}  ${theme.thin} w-full overflow-y-scroll scrollbar scrollbar-thin h-screen ${!PSselectedPage ? 'pb-40' : 'pb-[13rem]'}`}>
        {/* Tab Body Section 4 */}

        {/* Primary Information */}
        <div className='flex flex-row justify-between gap-2 w-full'>
          <div className='w-[44%] shadow font-medium bg-white  '>
            <div className={'bg-[#4d6d9a] flex justify-between text-white text-left p-2 rounded-t'}> <p className='text-sm font-medium'>Primary Information</p></div>
            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
              <p className='w-1/2  text-sm font-bold  text-gray-700'>Clinic Location</p>
              <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.brandcode} </p>
            </div>
            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
              <p className='w-1/2  text-sm font-bold  text-gray-700'>Chart Location</p>
              <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.ChartLocationName}</p>
            </div>
            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
              <p className='w-1/2  text-sm font-bold  text-gray-700'>How did you hear about us?</p>
              <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.HowHear} </p>

            </div>
            <div className={'w-full text-left flex    '}>
              <div className={'w-1/2 text-left p-2 flex     ' + theme.hoverBg}>
                <p className='w-1/2  text-sm font-bold  text-gray-700'>Clinician</p>
                <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.username} </p>
              </div>
              <div className={'w-1/2 text-left p-2 flex  border-l-[2px]  border-l-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sm font-bold  text-gray-700'>Chill Patient?</p>
                <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData?.chillpatient ? 'Yes' : 'No'}</p>
              </div>
            </div>
          </div>
          {/* Referral information*/}
          <div className='w-[33%] shadow font-medium bg-white '>
            <div className={'bg-[#4d6d9a] flex justify-between text-white text-left p-2 rounded-t'}> <p className='text-sm font-medium'>Referral Information</p></div>
            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
              <p className='w-1/2  text-sm font-bold  text-gray-700'>Referring GP Name</p>
              <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData?.PatientRefGPName}</p>
            </div>
            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
              <p className='w-1/2  text-sm font-bold  text-gray-700'>Referring GP Clinic</p>
              <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientRefGPClinic} </p>
            </div>
            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
              <p className='w-1/2  text-sm font-bold  text-gray-700'>Referring GP Address</p>
              <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientRefGPAdd
                // + ' ' + coupleData.PatientRefGPSuburb + ' ' + coupleData.PatientRefGPState + ' ' + coupleData.PatientRefGPPostCode
              }</p>
            </div>
          </div>
          {/* Couple Requirements*/}
          <div className='w-[33%] shadow font-medium bg-white  p'>
            <div className={'bg-[#4d6d9a] flex justify-between text-white text-left p-2 rounded-t'}> <p className='text-sm font-medium'>Couple Requirements</p></div>
            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
              <p className='w-1/2  text-sm font-bold  text-gray-700'>Disability?</p>
              <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData?.disability ? coupleData.disability : 'No'}</p>
            </div>
            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
              <p className='w-1/2  text-sm font-bold  text-gray-700'>Adjustments required?</p>
              <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.disabilityAdjustment} </p>
            </div>
            <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
              <p className='w-1/2  text-sm font-bold  text-gray-700'>Receive Marketing</p>
              <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.commAllowedMarketing ? 'Yes' : 'No'}</p>
            </div>
          </div>
        </div>

        <div className='w-full flex overflow-hidden gap-x-2 py-2' >
          {/* Patient Information */}
          <div className={`  w-1/2 rounded`}>
            <div className={`${coupleData.PatientGender === 'F' ? 'bg-[#fff6f6]' : "bg-[#f0f5ff]"} w-full shadow  mb-2 font-medium    `}>
              <div className={`${coupleData.PatientGender === 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}>
                <p className='text-sm font-medium'>Patient Information</p>
                {/* {genderIcon(coupleData?.patientGender, 15, 13)} */}
                {/* {genderIcon(selectedCoupletDetails?.partnerGender, 15, 13)} */}

                {coupleData.PatientGender === 'F' ?
                  <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                  </svg>
                  :
                  <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="White" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                }
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200 '>
                <div className={'w-1/2 text-left  flex  p-2   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Last name</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientLastName}</p>
                </div>
                <div className={'w-1/2 text-left  flex p-2   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>First name</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientFirstName}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={'w-1/2 text-left flex   p-2  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>DOB</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientDOB}</p>
                </div>
                <div className={'w-1/2 text-left  flex p-2  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Monash URN</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientMonashURN}</p>
                </div>
              </div>
              <div className='w-full items-center  flex   border-b-[2px]  border-b-gray-200'>
                <div className={'w-1/2 text-left flex p-2      ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Maiden Name: </p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientMaidenName}</p>
                </div>
                <div className={'w-1/2 text-left flex p-2 ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Middle Name: </p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientMiddleName ? coupleData.PatientMiddleName : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center  flex   border-b-[2px]  border-b-gray-200'>
                <div className={'w-1/2 text-left  flex p-2   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Uploaded ID Card</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{ }</p>
                </div>
                <div className={'w-1/2 text-left flex p-2   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Gender Identify As
                  </p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientGenderIdentifyAs ? coupleData.PatientGenderIdentifyAs : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center  flex   border-b-[2px]  border-b-gray-200'>
                <div className={'w-1/2 text-left  flex p-2   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Intersex</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientIntersex ? coupleData.PatientIntersex : 'N/A'}</p>
                </div>
                <div className={'w-1/2 text-left flex  p-2  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Sexuality</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.sexorientdesc ? coupleData.sexorientdesc : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center  flex   border-b-[1px]  border-b-gray-200'>
                <div className={'w-1/2 text-left  flex  p-2  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Pro-nouns</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.sexname ? coupleData.sexname : 'N/A'}</p>
                </div>
                <div className={'w-1/2 text-left flex  p-2    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Security Word</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientSecurityWord}</p>
                </div>
              </div>

            </div>
            {/* Patient Contact Information */}
            <div className={`${coupleData.PatientGender == 'F' ? 'bg-[#fff6f6]' : "bg-[#f0f5ff]"} w-full shadow  mb-2 font-medium  `}>
              <div className={`${coupleData.PatientGender == 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}> <p className='text-sm font-bold'>Patient Contact Information</p></div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sm font-bold  text-gray-700'>Residential Address</p>
                <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientResAddress}</p>
              </div>
              <div className={' text-left p-1 py-2 mx-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sm font-bold  text-gray-700'>Postal Address</p>
                <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientPostAddress}</p>
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={'w-1/2 text-left  p-2  flex   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Phone (H)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientPhoneHome}</p>
                </div>
                <div className={'w-1/2 text-left p-2  flex border-l-[2px]  border-b-gray-200  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Phone (W)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientPhoneWork ? coupleData.PatientPhoneWork : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={'w-1/2 text-left p-2  flex ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Phone (M)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientPhoneMobile}</p>
                </div>
                <div className={'w-1/2 text-left p-2  flex  border-l-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Email Address</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientEmail}</p>
                </div>
              </div>
              <div className={' text-left p-2 flex  border-b-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                <p className='w-1/2  text-sm font-bold  text-gray-700'>Preferred Contact Method</p>
                <p className='cursor-pointer text-sm  text-gray-700 flex items-center'>{coupleData.PatientPreferredContactMethod ? coupleData.PatientPreferredContactMethod : 'N/A'}</p>
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={'w-1/2 text-left p-2  flex ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Emergency Contact Name</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientEmergencyName ? coupleData.PatientEmergencyName : 'N/A'}</p>
                </div>
                <div className={'w-1/2 text-left p-2 flex  border-l-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Emergency Contact Number</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientEmergencyNumber ? coupleData.PatientEmergencyNumber : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[1px]  border-b-gray-200'>
                <div className={'w-1/2 text-left p-2  flex ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Work Suburb</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientWorkSuburb ? coupleData.PatientWorkSuburb : 'N/A'}</p>
                </div>
                <div className={'w-1/2 text-left p-2 flex  border-l-[2px]  border-b-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Work Postcode</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientWorkPostcode ? coupleData.PatientWorkPostcode : 'N/A'}</p>
                </div>
              </div>
            </div>
            {/* Patient Medical Detail */}
            <div className={`${coupleData.PatientGender == 'F' ? 'bg-[#fff6f6]' : "bg-[#f0f5ff]"} w-full shadow  mb-2 font-medium  `}>
              <div className={`${coupleData.PatientGender == 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}>
                <p className='text-sm font-medium'>Patient Medical Details</p>
              </div>
              {/* ------ */}

              <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200 py-1 ' + theme.hoverBg}>
                <div className='xl:w-1/2 w-full cursor-pointer'>
                  <div className={'text-left p-1 px-2  flex items-center w-full '}>
                    <p className='w-1/2  text-sm font-bold  text-gray-700'>Medicare Card No</p>
                    <p className='W-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientMedicareNumber}</p>
                  </div>
                  <div className={'text-left p-1 px-2 flex items-center w-full '}>
                    <p className='w-1/2  text-sm font-bold  text-gray-700'>Full name on Medicare card:</p>
                    <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientMedicareName ? coupleData.PatientMedicareName + ' ' + coupleData.PatientMedicareSurname : 'N/A'}</p>
                  </div>
                </div>
                <div className='xl:w-1/2 w-full '>
                  <div className={'text-left p-1 px-2  flex items-center w-full '}>
                    <p className='w-1/2  text-sm font-bold  text-gray-700'>Ref/IRN:</p>
                    <p className='w-1/2 text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientReference ? coupleData.PatientReference : 'N/A'}</p>
                  </div>
                  <div className={'text-left p-1 px-2 flex items-center w-full '}>
                    <p className='w-1/2  text-sm font-bold  text-gray-700'>Expiry:</p>
                    <p className='w-1/2 text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientReferenceExpiry ? coupleData.PatientReferenceExpiry : 'N/A'}</p>
                  </div>
                </div>
              </div>

              {/* ------ */}

              <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Health Care Card No.</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientPensionNumber ? coupleData.PatientPensionNumber : 'N/A'}</p>
                </div>

                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Expiry.</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientPensionExpiry ? coupleData.PatientPensionExpiry : 'N/A'}</p>
                </div>
              </div>
              <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Private Heath Fund</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientPrivinsFund ? coupleData.PatientPrivinsFund : 'N/A'}</p>
                </div>
                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>No</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientPrivinsFundNumber ? coupleData.PatientPrivinsFundNumber : 'N/A'}</p>
                </div>
                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Level of Cover</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientPrivinsFundLevelofCover ? coupleData.PatientPrivinsFundLevelofCover : 'N/A'}</p>
                </div>
              </div>
              <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200   ' + theme.hoverBg} style={{ backgroundColor: patientData?.color }}>
                <div className='xl:w-1/3 w-full p-2 flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Height (cm)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientHeight}</p>
                </div>
                <div className='xl:w-1/3 w-full flex  p-2 border-x-2'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Weight (kg)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientWeight}</p>
                </div>
                <div className='xl:w-1/3 w-full  flex'>
                  <p className='w-1/3  text-sm font-bold p-2 text-gray-700'>BMI</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  p-2 flex items-center'>{calculateBMIpatient(coupleData.PatientHeight, coupleData.PatientWeight)}</p>
                  <div className='flex w-1/3 items-center justify-center pt-2 h-4 w-4'>{patientData && <p className='font-normal h-4 w-4'>{patientData.icon}</p>}</div>
                </div>

              </div>
              <div className={'w-full text-left flex  border-b-[1px]  border-b-gray-200   ' + theme.hoverBg}>
                <div className={'w-1/2 text-left p-2 flex    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Allergies</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientAllergies ? coupleData.PatientAllergies : 'N/A'}</p>
                </div>
                <div className={'w-1/2 text-left p-2 flex  border-l-[2px]  border-l-gray-200   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Occupation</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientOccupation ? coupleData.PatientOccupation : 'N/A'}</p>
                </div>
              </div>
            </div>
            {/*Patient Nationality Details*/}
            <div className={`${coupleData.PatientGender == 'F' ? 'bg-[#fff6f6]' : "bg-[#f0f5ff]"} w-full shadow   font-medium  `}>
              <div className={`${coupleData.PatientGender == 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}> <p className='text-sm font-medium'>Patient Nationality Details</p></div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={'w-1/2 text-left p-2  flex    ' + theme.hoverBg}>
                  <p className='w-3/4  text-sm font-bold  text-gray-700'>Australian or New Zealand Citizen?</p>
                  <p className='w-1/4  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientAus_Nz_Citizen ? 'Yes' : 'No'}</p>
                </div>
                <div className={'w-1/2 text-left p-2  flex  border-l-[2px]  border-l-gray-200   ' + theme.hoverBg}>
                  <p className='w-3/4  text-sm font-bold  text-gray-700'>Aboriginal or Torres Strait Islander Origin?</p>
                  <p className='w-1/4  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>Country of Birth</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientBirthCountry}</p>
                </div>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>State of Birth (If born Australia)</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientBirthState ? coupleData.PatientBirthState : 'N/A'}</p>
                </div>
              </div>


              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={'w-1/2 text-left p-2  flex  ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>Country of Residence</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientCountry_Of_Residence ? coupleData.PatientCountry_Of_Residence : 'N/A'}</p>
                </div>
                <div className={'w-1/2 text-left p-2  flex  border-l-[2px]  border-l-gray-200   ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>Ethnic Background</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientEthnicOrigin}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[1px]  border-b-gray-200'>
                <div className={'w-1/2 text-left p-2  flex ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>First Language</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PatientFirstLanguage ? coupleData.PatientFirstLanguage : 'N/A'}</p>
                </div>
                <div className={'w-1/2 text-left p-2  flex  border-l-[2px]  border-l-gray-200   ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>Interpreter Required?</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>
              </div>

            </div>
          </div>
          {/* Partner Information */}
          <div className={` w-1/2 rounded`}>
            <div className={`${coupleData.PartnerGender === ' F' ? 'bg-[#fff6f6]' : "bg-[#f0f5ff]"} w-full shadow  mb-2 font-medium   `}>
              <div className={`${coupleData.PartnerGender === 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"} flex justify-between  text-left p-2 rounded-t`}>
                <p className='text-sm font-medium'>Partner Information</p>
                {/* {genderIcon(selectedCoupletDetails?.patientGender, 15, 13)} */}
                {/* {genderIcon(coupleData?.partnerGender, 15, 13)} */}
                {coupleData?.PartnerGender === 'F' ?
                  <svg width="15" height="20" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.79965 4.90032C9.79975 3.9611 9.5299 3.04163 9.02224 2.25143C8.51458 1.46122 7.7905 0.833572 6.93623 0.443223C6.08197 0.0528732 5.13351 -0.0837271 4.20381 0.0496892C3.27411 0.183105 2.40234 0.580916 1.69232 1.19575C0.982305 1.81058 0.463955 2.61652 0.199002 3.5176C-0.0659504 4.41868 -0.0663413 5.37693 0.197876 6.27822C0.462093 7.17952 0.979785 7.98588 1.6893 8.60129C2.39882 9.2167 3.27026 9.61523 4.19985 9.7494V10.7334H2.56658C2.38093 10.7334 2.20289 10.8072 2.07162 10.9385C1.94035 11.0697 1.8666 11.2478 1.8666 11.4334C1.8666 11.6191 1.94035 11.7971 2.07162 11.9284C2.20289 12.0597 2.38093 12.1334 2.56658 12.1334H4.19985V13.3C4.19985 13.4857 4.2736 13.6637 4.40487 13.795C4.53614 13.9263 4.71418 14 4.89983 14C5.08547 14 5.26351 13.9263 5.39478 13.795C5.52605 13.6637 5.5998 13.4857 5.5998 13.3V12.1334H7.23308C7.41872 12.1334 7.59676 12.0597 7.72803 11.9284C7.85931 11.7971 7.93305 11.6191 7.93305 11.4334C7.93305 11.2478 7.85931 11.0697 7.72803 10.9385C7.59676 10.8072 7.41872 10.7334 7.23308 10.7334H5.5998V9.7494C6.76527 9.57971 7.83082 8.99649 8.60188 8.10623C9.37295 7.21597 9.79808 6.07807 9.79965 4.90032ZM1.39995 4.90032C1.39995 4.20811 1.60521 3.53145 1.98979 2.95589C2.37436 2.38034 2.92096 1.93176 3.56048 1.66686C4.2 1.40196 4.90371 1.33265 5.58262 1.46769C6.26153 1.60274 6.88515 1.93607 7.37461 2.42554C7.86408 2.915 8.19741 3.53862 8.33245 4.21753C8.4675 4.89644 8.39819 5.60015 8.13329 6.23967C7.86839 6.87919 7.41981 7.42579 6.84425 7.81036C6.2687 8.19493 5.59204 8.4002 4.89983 8.4002C3.97193 8.39912 3.08235 8.03003 2.42623 7.37391C1.77011 6.71779 1.40103 5.82822 1.39995 4.90032Z" fill="white" />
                  </svg> :
                  <svg width="17" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 1L9.6 6.4M15 1H10M15 1V6M1 10C1 11.3261 1.52678 12.5979 2.46447 13.5355C3.40215 14.4732 4.67392 15 6 15C7.32608 15 8.59785 14.4732 9.53553 13.5355C10.4732 12.5979 11 11.3261 11 10C11 8.67392 10.4732 7.40215 9.53553 6.46447C8.59785 5.52678 7.32608 5 6 5C4.67392 5 3.40215 5.52678 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                }
              </div>
              <div className='w-full items-center  flex  border-b-[2px]  border-b-gray-200  '>
                <div className={'w-1/2 text-left  flex   p-2  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Last name</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerLastName}</p>
                </div>
                <div className={'w-1/2 text-left  flex  p-2   ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Preferred First name
                  </p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerFirstName}</p>
                </div>
              </div>
              <div className='w-full items-center  flex border-b-[2px]  border-b-gray-200   '>
                <div className={'w-1/2 text-left  flex  p-2 ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>DOB</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerDOB}</p>
                </div>
                <div className={'w-1/2 text-left  flex   p-2  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Monash URN</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerMonashURN}</p>
                </div>
              </div>
              <div className='w-full items-center  flex  border-b-[2px]  border-b-gray-200  '>
                <div className={'w-1/2 text-left  flex p-2    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Maiden Name</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerMaidenName}</p>
                </div>
                <div className={'w-1/2 text-left  flex p-2    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Middle Name </p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerMiddleName ? coupleData.PartnerMiddleName : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center  flex  border-b-[2px]  border-b-gray-200  '>
                <div className={'w-1/2 text-left  flex p-2    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Uploaded ID Card</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{ }</p>
                </div>
                <div className={'w-1/2 text-left  flex p-2    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Gender Identify As</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerGenderIdentifyAs ? coupleData.PartnerGenderIdentifyAs : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center  flex   border-b-[1px]  border-b-gray-200 '>
                <div className={'w-1/2 text-left  flex p-2    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Intersex</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerIntersex ? coupleData.PartnerIntersex : 'N/A'}</p>
                </div>
                <div className={'w-1/2 text-left  flex p-2    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Sexuality</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center '>{coupleData.sexorientdesc ? coupleData.sexorientdesc : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center  flex  border-b-[1px]  border-b-gray-200  '>
                <div className={'w-1/2 text-left  flex p-2    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Pro-nouns</p>
                  <p className='cursor-pointer text-sm  text-gray-700 flex items-center font-bold'>{coupleData.sexname ? coupleData.sexname : 'N/A'}</p>
                </div>
                <div className={'w-1/2 text-left  flex p-2    ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Security Word</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerSecurityWord}</p>
                </div>
              </div>

            </div>
            {/* Partner Contact Information */}
            <div className={`${coupleData.PartnerGender == ' F' ? 'bg-[#fff6f6]' : "bg-[#f0f5ff]"}  w-full shadow mb-2 font-medium  `}>
              <div className={`${coupleData.PartnerGender == 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"}  flex justify-between text-white text-left p-2 rounded-t`}> <p className='text-sm font-bold text-gray-800'>Partner Contact Information</p></div>
              <div className={' w-full text-left p-2 flex  border-b-[2px]  border-b-gray-200  ' + theme.hoverBg}>
                <p className='w-1/2  text-sm font-bold  text-gray-700'>Residential Address</p>
                <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerResAddress}</p>
              </div>
              <div className={' wfull text-left p-2 flex  border-b-[2px]  border-b-gray-200  ' + theme.hoverBg}>
                <p className='w-1/2  text-sm font-bold  text-gray-700'>Postal Address</p>
                <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerPostAddress}</p>
              </div>
              <div className={' w-full text-left p-2 flex  border-b-[2px]  border-b-gray-200  ' + theme.hoverBg}>
                <p className='w-1/2  text-sm font-bold  text-gray-700'>Preferred Contact Method</p>
                <p className='cursor-pointer text-sm  text-gray-700 flex items-center '>{coupleData.PartnerPreferredContactMethod ? coupleData.PartnerPreferredContactMethod : 'N/A'}</p>
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Phone (H)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerPhoneMobile}</p>
                </div>
                <div className={' w-1/2 text-left p-2 flex  border-l-[2px]  border-l-gray-200  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Phone (W)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerPhoneWork}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Phone (M)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerPhoneMobile}</p>
                </div>
                <div className={' w-1/2 text-left p-2 flex border-l-[2px]  border-l-gray-200 ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Email Address</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerEmail}</p>
                </div>
              </div>

              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Emergency Contact Name</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerEmergencyName ? coupleData.PartnerEmergencyName : 'N/A'}</p>
                </div>

                <div className={' w-1/2 text-left p-2 flex border-l-[2px]  border-l-gray-200 ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Emergency Contact Number</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerEmergencyNumber ? coupleData.PartnerEmergencyNumber : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[1px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Work Suburb</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerWorkSuburb ? coupleData.PartnerWorkSuburb : 'N/A'}</p>
                </div>
                <div className={' w-1/2 text-left p-2 flex border-l-[2px]  border-l-gray-200 ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Work Postcode</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerWorkPostcode ? coupleData.PartnerWorkPostcode : 'N/A'}</p>
                </div>
              </div>
            </div>
            {/*Partner Medical Details */}
            <div className={`${coupleData.PartnerGender == ' F' ? 'bg-[#fff6f6]' : "bg-[#f0f5ff]"} w-full shadow  mb-2 font-medium   `}>

              <div className={`${coupleData.PartnerGender == 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"}  flex justify-between text-left p-2 rounded-t`}>
                <p className='text-sm font-bold'>Partner Medical Details</p>
              </div>

              <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200 py-1 ' + theme.hoverBg}>
                <div className='xl:w-1/2 w-full cursor-pointer'>
                  <div className={'text-left p-1 px-2  flex items-center w-full '}>
                    <p className='w-1/2  text-sm font-bold  text-gray-700'>Medicare Card No</p>
                    <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerMedicareNumber}</p>
                  </div>
                  <div className={'text-left p-1 px-2  flex items-center w-full '}>
                    <p className='w-1/2  text-sm font-bold  text-gray-700'>Full name on Medicare card:</p>
                    <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerMedicareName ? coupleData.PartnerMedicareName + ' ' + coupleData.PartnerMedicareSurname : 'N/A'}</p>
                  </div>
                </div>
                <div className='xl:w-1/2 w-full '>
                  <div className={'text-left p-1 px-2  flex items-center w-full '}>
                    <p className='w-1/2  text-sm font-bold  text-gray-700'>Ref/IRN:</p>
                    <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerReference ? coupleData.PartnerReference : 'N/A'}</p>
                  </div>
                  <div className={'text-left p-1 px-2  flex items-center w-full '}>
                    <p className='w-1/2  text-sm font-bold  text-gray-700'>Expiry:</p>
                    <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerReferenceExpiry ? coupleData.PartnerReferenceExpiry : 'N/A'}</p>
                  </div>
                </div>
              </div>

              {/* -------------------- */}

              <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Health Care Card No</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerPensionNumber ? coupleData.PartnerPensionNumber : 'N/A'}</p>
                </div>
                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Expiry</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerPensionExpiry ? coupleData.PartnerPensionExpiry : 'N/A'}</p>
                </div>
              </div>
              <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  p-2 ' + theme.hoverBg}>
                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Private Heath Fund</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerPrivinsFund ? coupleData.PartnerPrivinsFund : 'N/A'}</p>
                </div>
                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>No</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerPrivinsFundNumber ? coupleData.PartnerPrivinsFundNumber : 'N/A'}</p>
                </div>
                <div className='xl:w-1/2 w-full flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Level of Cover</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerPrivinsFundLevelofCover ? coupleData.PartnerPrivinsFundLevelofCover : 'N/A'}</p>
                </div>
              </div>

              <div className={'w-full flex flex-wrap border-b-[2px] border-b-gray-200  ' + theme.hoverBg} style={{ backgroundColor: partnerData?.color }}>
                <div className='xl:w-1/3 w-full p-2 flex'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Height (cm)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerHeight}</p>
                </div>
                <div className='xl:w-1/3 w-full p-2 flex border-x-2'>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Weight (kg)</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerWeight}</p>
                </div>
                <div className='xl:w-1/3 w-full  flex'>
                  <p className='w-1/3  text-sm font-bold p-2 text-gray-700'>BMI</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex p-2 items-center'>
                    {/* <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{patientData.patientBMI} */}
                    {calculateBMIpatient(Number(coupleData.PartnerHeight), Number(coupleData.PartnerWeight))}
                  </p>
                  <div className='flex w-1/3 items-center justify-center  h-4 w-4'>{partnerData && <p className='font-normal h-4 w-4 pt-1'>{partnerData.icon}</p>}</div>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[1px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Allergies</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerAllergies ? coupleData.PartnerAllergies : 'N/A'}</p>
                </div>
                <div className={' w-1/2 text-left p-2 flex border-l-[2px]  border-l-gray-200 ' + theme.hoverBg}>
                  <p className='w-1/2  text-sm font-bold  text-gray-700'>Occupation.</p>
                  <p className='w-1/2  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>
              </div>
            </div>
            {/*Partner Nationality Details*/}
            <div className={`${coupleData.PartnerGender == ' F' ? 'bg-[#fff6f6]' : "bg-[#f0f5ff]"} w-full shadow  font-medium  `}>
              <div className={`${coupleData.PartnerGender == 'F' ? 'bg-[#FE9696]' : "bg-[#A9C6FF]"}  flex justify-between  text-left p-2 rounded-t`}> <p className='text-sm font-medium'>Partner Nationality Details</p></div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-3/4  text-sm font-bold  text-gray-700'>Australian or New Zealand Citizen?</p>
                  <p className='w-1/4  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerAus_Nz_Citizen ? 'Yes' : 'No'}</p>
                </div>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-3/4  text-sm font-bold  text-gray-700'>Aboriginal or Torres Strait Islander Origin?</p>
                  <p className='w-1/4  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>Country of Birth</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerBirthCountry}</p>
                </div>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>State of Birth (If born Australia)</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerBirthState ? coupleData.PartnerBirthState : 'N/A'}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[2px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>Country of Residence</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerCountry_Of_Residence ? coupleData.PartnerCountry_Of_Residence : 'N/A'}</p>
                </div>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>Ethnic Background</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerOccupation}</p>
                </div>
              </div>
              <div className='w-full items-center flex   border-b-[1px]  border-b-gray-200'>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>First Language</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerFirstLanguage ? coupleData.PartnerFirstLanguage : 'N/A'}</p>
                </div>
                <div className={' w-1/2 text-left p-2 flex  ' + theme.hoverBg}>
                  <p className='w-2/3  text-sm font-bold  text-gray-700'>Interpreter Required?</p>
                  <p className='w-1/3  text-sm text-gray-600 font-medium  flex items-center'>{coupleData.PartnerOccupation ? coupleData.PartnerOccupation : 'N/A'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div >
    </div >
  )
}

const mapStateToProps = (state) => ({

})
export default connect(mapStateToProps, {
  ApiCall_GET
})(CoupleInfo);
